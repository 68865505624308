import React, { FC, useContext } from 'react';
import cn from 'classnames';

import { Col, Row } from '@guestyci/foundation/Layout';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import CurrencySymbols from '@guestyci/foundation/constants/currencySymbols';
import RaisedButton from '@guestyci/foundation/RaisedButton';

import { ListingImage } from './ListingImage';
import TTheme, { IListing } from '../../common/types';
import { ListingRate } from '../ListingRate';
import { Amenities } from './Amenities';
import { Price } from './Price';
import { WebsiteSettingsContext } from '../../context/SettingsProvider';
import Translations from '../../translations';

type TCurrencySymbols = typeof CurrencySymbols;

const useStyles = createStyles((theme: TTheme) => ({
  button: {
    display: 'flex',
    border: 'none',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    background: 'none',
    padding: 0,
    margin: 0,

    '@media (max-width: 695px)': {
      margin: '0 auto',
      maxWidth: 300,
    },
    '@media (min-width: 696px) and (max-width: 1023px)': {
      width: 665,
      margin: '0 auto',
    }
  },
  root: {
    borderBottom: `1px solid ${theme.palette.secondary.background}`,
    marginBottom: theme.spacer(8),
    marginTop: theme.spacer(4),
    paddingBottom: theme.spacer(6),
  },
  content: {
    width: '100%',
  },
  title: {
    color: theme.palette.label,
    '&.mobile': {
      '@media (max-width: 695px)': {
        marginTop: theme.spacer(3),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 'inherit',
      },
    },
  },
  description: {
    color: theme.palette.disabledText,
    lineHeight: '20px',
    marginTop: theme.spacer(5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 5, // number of lines to show
    WebkitBoxOrient: 'vertical',
    width: '100%',

    '@media (min-width: 696px) and (max-width: 1023px)': {
      WebkitLineClamp: 3,
    }
  },
  startingFrom: {
    color: theme.palette.text.secondaryText,
  },
  price: {
    '@media (max-width: 695px)': {
      display: 'none',
    },
    '@media (min-width: 696px)': {
      display: 'inherit',
    }
  },
  priceMobile: {
    marginLeft: 'auto',
    '@media (max-width: 695px)': {
      display: 'inherit',
      marginTop: theme.spacer(2),
      '& .price': {
        fontSize: 16,
        fontWeight: 600,
      }
    },
    '@media (min-width: 696px)': {
      display: 'none'
    }
  },
  submit: {
    float: 'right',
    backgroundColor: theme.palette.text.primaryText,
    marginTop: theme.spacer(6),
    marginLeft: theme.spacer(7),
  },
  image: {
    marginRight: theme.spacer(3),
    '@media (max-width: 695px)': {
      display: 'none',
    },
    [theme.breakpoints.create('lg')]: {
      display: 'inherit',
    }
  },
  imageMobile: {
    marginRight: theme.spacer(3),
    '@media (max-width: 695px)': {
      display: 'inherit',
    },
    '@media (min-width: 696px)': {
      display: 'none'
    }
  },
  amenities: {
    marginTop: theme.spacer(4),
  },
  listingRate: {
    '&.mobile': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacer(3),
        marginTop: theme.spacer(3),
      },
      '@media (min-width: 696px)': {
        marginBottom: 'inherit',
        marginTop: 'inherit',
      },
    },
  }
}));

interface IListingListProps {
  listing: IListing,
  onClick: () => void;
}

export const ListingList: FC<IListingListProps> = ({ listing, onClick }) => {
  const classes = useStyles();
  const { appData } = useContext(WebsiteSettingsContext);
  const { title, pictures = [], publicDescription: { summary: description } = {}, prices, amenities = [] } = listing;
  const t = Translations.getInstance(appData.locale).getTranslations.bind(Translations.getInstance(appData.locale));

  // @ts-ignore
  const currencySymbol: string = (CurrencySymbols as TCurrencySymbols)[prices?.currency]?.symbol;

  return (
    <button className={classes.button} onClick={onClick} type="button">
      <Amenities className={classes.amenities} amenities={amenities} />
      <Row fullWidth justify="between" className={classes.root}>
        <Row>
          <ListingImage className={classes.image} isGridView={false} name={title} pictures={pictures} />
        </Row>

        <div className={cn(classes.content, 'clearfix')}>
          <Col className={classes.imageMobile}>
            <ListingImage isGridView={false} name={title} pictures={pictures} />
          </Col>
          <Row justify="between" fullWidth>
            <Col>
              <TextField variant="h3" align="left" className={cn(classes.title, 'mobile')}>{title}</TextField>
              <ListingRate className={cn(classes.listingRate, 'mobile')} listing={listing} />
              <TextField variant="h4" align="left" className={classes.description}>{description}</TextField>
            </Col>
            <Price className={classes.price} currencySymbol={currencySymbol} prices={prices} locale={appData.locale} />
          </Row>

          <RaisedButton className={cn(classes.submit, classes.price)}>
            <TextField variant="h5">{t('View prices')}</TextField>
          </RaisedButton>
          <Price className={classes.priceMobile} currencySymbol={currencySymbol} prices={prices} locale={appData.locale} />
        </div>
      </Row>
    </button>
  );
};

export default ListingList;
